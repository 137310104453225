import React from 'react'
import { Link } from 'react-router-dom'
import './Menu.scss'

class Menu extends React.Component {
  render () {
    let classHide = this.props.display ? '' : 'hide'
    return <div className={'menu fade-in-slow ' + classHide}>
      <div className='container' onClick={this.props.toggleMenu}>
        <Link to='/'>
          <div className='menu-item'>Home</div>
        </Link>
        <a href='https://github.com/s-silva' target='_blank' rel="noreferrer">
          <div className='menu-item'>GitHub</div>
        </a>
        <a href='https://dribbble.com/sandaruwan' target='_blank' rel="noreferrer">
          <div className='menu-item'>Dribbble</div>
        </a>
        <a href='https://www.linkedin.com/in/sandaruwansilva' target='_blank' rel="noreferrer">
          <div className='menu-item'>LinkedIn</div>
        </a>
        <a href='&#109;&#97;&#105;&#108;&#116;&#111;&#58;%73%61%6E%40%72%65%73%61%6D%70%6C%65%2E%6E%65%74'>
          <div className='menu-item'>Contact</div>
        </a>
      </div>
    </div>
  }
}

export default Menu
