import React from 'react'
import './Flow.scss'

class Branch extends React.Component {
  render () {
    return <div className={`branch lv${this.props.level}`} >
      {this.props.data.map(child => <Entry sole={this.props.data.length === 1} data={child} level={this.props.level} />)}
    </div>
  }
}

class Entry extends React.Component {
  render () {
    return <div className={`entry ${this.props.sole ? 'sole' : ''}`} >
      <Element>{this.props.data.title}</Element>
      {this.props.data.children &&
        <Branch data={this.props.data.children} level={this.props.level + 1} />}
    </div>
  }
}

class Element extends React.Component {
  render () {
    return <div className={`element`} >
      {this.props.children}
    </div>
  }
}

class Flow extends React.Component {
  render () {
    return <div className={'flow-container'}>
      <div className={'flow'} >
        {this.props.data && <Branch data={this.props.data} level={1} />}
      </div>
    </div>
  }
}

export default Flow
