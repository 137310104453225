import React from 'react'
import { withRouter } from 'react-router'

import Content from '../../content/work'
import Story from '../../components/Story'

import './Work.scss'

class Work extends React.Component {
  render () {
    const content = Content.find(item => item.slug === this.props.match.params.project);

    if(!content) {
      return <div className='work-container'>
        <div className='work-404'>
          <div className='work-header'>
            <div className='work-error'>404</div>
            <div className='work-error-title'>Page not found</div>
            Please contact me if you need more information about the missing or
            updated page.
          </div>
        </div>
      </div>
    }

    return <Story content={content}/>
  }
}

export default withRouter(Work)
