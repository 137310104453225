import React from 'react'
import Flow from '../../components/Flow'

import Headline from '../../components/Headline'

import './Story.scss'

class Story extends React.Component {
  render () {
    const content = this.props.content

    if(!content) {
      return <div className='work-container'>
        <div className='work'>
          <div className='work-header'>
            <div className='work-title'>Article not found</div>
            Please contact me if you need more information about the missing or
            updated page.
          </div>
        </div>
      </div>
    }

    return <div className='work-container'>
      <div className='work'>

      <Headline content={content.headline} />

        { /* <div className='work-header'>
          <div className='work-title'>{content.title}</div>
          <div className='work-timeline'>{content.time}</div>
          <div className='work-category'>{content.category}</div>
        </div> */}
        <div className='work-header'>
          {content.time && <div>
            <div className='work-title'>Time</div>
            <div className='work-info'>{content.time}</div>
          </div>}
          {content.category && <div>
            <div className='work-title'>Category</div>
            <div className='work-info'>{content.category}</div>
          </div>}
          {content.website && <div>
            <div className='work-title'>Website</div>
            <div className='work-info'><a target='_blank' href={content.website} rel="noreferrer">{content.website}</a></div>
          </div>}
          {content.source && <div>
            <div className='work-title'>Source Code</div>
            <div className='work-info'><a target='_blank' href={content.source} rel="noreferrer">{content.source}</a></div>
          </div>}
        </div> 
        <div className='work-content'>
          <section className='work-description'>
            {content.summary.text}
          </section>
          {content.tech && <section>
            <Flow data={content.tech} />
          </section>}
          {content.sections.map((section, index) => <section key={index}>
            <h2 className='slideDown'>{section.title}</h2>
            <div className='section-description'>{section.text || content.description}</div>
            {section.images.map((image, index) => <div className='img-container' key={index}>
              <img className={section.imageMode || 'auto'} src={image} alt='Preview' />
            </div>)}
            {section.video && <div className='video-content'>
              <div className='video'>
        				<iframe src={ section.video.src }
                  width={ section.video.width }
                  height={ section.video.height }
                  title={ section.video.src }
                  frameborder="0"
                  webkitAllowFullScreen
                  mozallowfullscreen
                  allowFullScreen></iframe>
        			</div>
            </div>}
          </section>)}
        </div>
      </div>
    </div>
  }
}

export default Story
