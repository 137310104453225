import React from 'react'
import { Link } from 'react-router-dom'
import './Home.scss'

import Work from '../../content/work'
import Publications from '../../content/publications'
import Libraries from '../../content/libraries'

import Headline from '../../components/Headline'
import Separator from '../../components/Separator'
import Story from '../../components/Story'

// var disableWaterScene = true

class Home extends React.Component {
  constructor (props) {
    super(props)
    this.state = { stories: 0 }
    this.loadMore = this.loadMore.bind(this)
  }

  loadMore () {
    if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight) {
      this.setState({ stories: this.state.stories + 1 })
    }
  }
  componentDidMount () {
    window.addEventListener('scroll', this.loadMore)
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this.loadMore)
  }

  render () {
    function pad(num, size) {
      num = num.toString();
      while (num.length < size) num = `0${num}`;
      return num;
    }

    return <div className='home'>
      <div className='home-content'>
        <Headline content={{
            heading: {
              lines: [ 'SANDARUWAN', 'SILVA' ],
              align: 'left'
            },
            subheading: {
              lines: ['PORTFOLIO'],//lines: [ 'A FULL-STACK DEVELOPER',
              //  'FROM', 'COLOMBO, SRI LANKA' ],
              align: 'expright',
              vline: 1,
              fontSize: 0.3
            },
            colors: {
              background: '#000',
              accent: '#f00',
              c1: '#00ff00',
              c2: '#ff0000',
              c3: '#ff00ff'
            }
        }} />

        <div className='heading'>
          <h3>Projects</h3>
          <div className='projects'>
            {Work.map((story, index) => <Link to={`/work/${story.slug}`} key={index}>
              <div className='project'>
                <div className='number'>{pad(index + 1, 2)}</div>
                <div className='title'>{story.title}</div>
                <div className='intro'>{story.summary.text || story.description}</div>
              </div>
            </Link>)}
          </div>

          <h3>Generative Art</h3>

          <div className='demos'>
            <div className='demo'>
              <a href="https://www.shadertoy.com/view/ftXyDr" target="_blank" rel="noreferrer">
                <div className='preview'>
                  <img src='demos/giza.jpg' alt='Demo' />
                </div>
              </a>
              <div className='title'>Giza Necropolis</div>
            </div>
            <div className='demo'>
              <div className='preview'>
                <img src='demos/particle.jpg' alt='Demo' />
              </div>
              <div className='title'>Particle Sky</div>
            </div>
            <div className='demo'>
              <div className='preview'>
                <img src='demos/demo-2.jpg' alt='Demo' />
              </div>
              <div className='title'>Rocky</div>
            </div>
            <div className='demo'>
              <div className='preview'>
                <img src='demos/demo-4.jpg' alt='Demo' />
              </div>
              <div className='title'>Terraform</div>
            </div>
            <div className='demo'>
              <div className='preview'>
                <img src='demos/demo-3.jpg' alt='Demo' />
              </div>
              <div className='title'>Martian</div>
            </div>
            <div className='demo'>
              <div className='preview'>
                <img src='demos/demo-1.jpg' alt='Demo' />
              </div>
              <div className='title'>Dreams</div>
            </div>
            <div className='demo'>
              <div className='preview'>
                <img src='demos/demo-5.jpg' alt='Demo' />
              </div>
              <div className='title'>Mystique</div>
            </div>
          </div>
          
          <h3>Libraries/Packages</h3>
          <div className='libraries'>
            {Libraries.map((pub, index) => <a key={index} target='_blank' rel="noreferrer" href={pub.url}>
              <div className='library'>
                <div className='number'>{pad(index + 1, 2)}</div>
                <div className='title'>{pub.title}</div>
                <div className='description'>{pub.description}</div>
              </div>
            </a>)}
          </div>

          <h3>Posts/Publications</h3>
          <div className='publications'>
            {Publications.map((pub, index) => <a key={index} target='_blank' rel="noreferrer" href={pub.url}>
              <div className='publication'>
                <div className='number'>{pad(index + 1, 2)}</div>
                <div className='date'>{pub.date}</div>
                <div className='title'>{pub.title}</div>
              </div>
            </a>)}
          </div>
        </div>
      </div>
      {Work.map((story, index) => {
        if (index >= this.state.stories) return <div key={index}/>
        return <div key={index}>
            <Separator content={story} index={index}/>
            <Story content={story} index={index}/>
          </div>
      })}
    </div>
  }
}

export default Home
