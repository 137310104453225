const data = {
  slug: 'natfie',
  title: 'Natfie',
  description: `Natfie is a location based social network with photo/media sharing,
    blogs, direct and group instant messaging and full content searches. You can
    build your profile and add friends and people around you and make posts that
    can be shared based on the location or the friend list. Built on PHP/Tornado
    with native JavaScript based frontend, it's been optimized to work on devices with
    minimal amount of resources.`,
  time: '2012',
  category: 'Location based social network.',
  categoryDescription: 'A location based social network with media sharing and blogs.',
  source: 'https://github.com/s-silva/natfie',
  headline: {
    heading: {
      lines: [ 'NATFIE' ],
      align: 'left'
    },
    subheading: {
      lines: [ 'LOCATION BASED',
        'SOCIAL NETWORK' ],
      align: 'left',
      vline: 1,
      fontSize: 0.3
    },
    colors: {
      background: '#0d5',
      accent: '#0f6',
      c1: '#ccff',
      c2: '#dd88ff',
      c3: '#ffffff'
    }
  },
  tech: [{
    title: 'Front-end',
    children: [{
      title: 'Native JavaScript',
      children: [{
        title: 'AJAX'
      }, {
        title: 'JSONP'
      }]
    }, {
      title: 'CSS',
      children: [{
        title: 'Custom minifier'
      }, {
        title: 'Custom file unification'
      }]
    }]
  }, {
    title: 'Back-end',
    children: [{
      title: 'PHP'
    }, {
      title: 'Python/Tornado'
    }]
  }],
  summary: {
    text: null,
    image: '/content/natfie/profile.png'
  },
  sections: [{
    title: 'Introduction',
    text: `Natfie is a location based social network with photo/media sharing,
      blogs, direcct and group instant messaging and full content searches. You can
      build your profile and add friends and people around you and make posts that
      can be shared based on the location or the friend list. Built on PHP/Tornado
      with native JavaScript based frontend, it's optimized to work on devices with
      minimal amount of resources.`,
    images: ['/content/natfie/login.png'],
    imageMode: 'expanded'
  }, {
    title: 'Profiles',
    text: `User profiles carry the basic details about each user with the posts
      they've made, each post and detail will be displayed to the viewer based on
      the author's privacy settings and based on the connection the viewer and the
      author has to each other. Each one of these fields can be edited in place,
      and a preview of the profile after the edits will be available when each
      change has been made.`,
    images: ['/content/natfie/profile.png'],
    imageMode: 'expanded'
  }, {
    title: 'Media',
    text: `Natfie provides each user an image gallery with multiple albums and
      built in photo editing, tagging and photo effects. The photo gallery can
      be viewed in three different ways - a grid of the whole gallery, an expanded
      slideshow or a full screen display of the photos with comment threads attached
      to it when necessary.

      The users can also share videos and links taken from different sources like
      YouTube/Vimeo, preview details of these links will be fetched from the sources
      when possible. And for a couple of specific sources, the app fetches details
      directly from their API.`,
    images: ['/content/natfie/images.png'],
    imageMode: 'expanded',
    imageBackground: '#505C68'
  }, {
    title: 'Account Management',
    text: `Most of the information added to the account at the creation can be
      edited later using Settings page, this includes privacy settings and themes
      for the app. The themes section includes a variety of options for home page
      post arrangement, this can flow vertically or be arranged in a larger grid.`,
    images: ['/content/natfie/settings.png'],
    imageMode: 'expanded',
    imageBackground: '#505C68'
  }]
}

export default data