import React from 'react'
import { Link } from 'react-router-dom'
import './Header.scss'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = { isHide : true }
    this.hideBar = this.hideBar.bind(this)
  }
  hideBar () {
    this.setState({ isHide: !(window.scrollY > 100) })
  }
  componentDidMount () {
    window.addEventListener('scroll', this.hideBar)
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this.hideBar)
  }
  render () {
    let classHide = this.state.isHide ? 'hide' : ''
    return <div className={'fixed-header fade-in-slow ' + classHide} >
      <div className='container'>
        <Link to='/'>
          <img className='logo' src='\logo.svg'  width='30px' alt="S."/>
        </Link>
        <div className='separator'/>
        <a target='_blank' href='https://github.com/s-silva' rel="noreferrer" >GitHub</a>
        <a target='_blank' href='https://dribbble.com/sandaruwan' rel="noreferrer" >Dribbble</a>
        <a target='_blank' href='https://www.linkedin.com/in/sandaruwansilva' rel="noreferrer">LinkedIn</a>
        <a href='&#109;&#97;&#105;&#108;&#116;&#111;&#58;%73%61%6E%40%72%65%73%61%6D%70%6C%65%2E%6E%65%74'>
          Contact
        </a>
        <div className='button-container'>
          <div className='button' onClick={this.props.toggleMenu}>
            <div className='line' />
            <div className='line' />
            <div className='line' />
          </div>
        </div>
      </div>
    </div>
  }
}

export default Header
