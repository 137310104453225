const data = {
  slug: 'audio',
  title: 'Audio Tools',
  description: `I've made audio/music related tools ranging from online
    metronomes, interactive scale/chord progression selection tools, audio plugins
    to minimalistic DAWs. The performance critical tools were generally written
    in C/C++ and others using Adobe ActionScript.`,
  category: 'Audio/music tools.',
  categoryDescription: 'Different audio production and music tools.',
  headline: {
    heading: {
      lines: [ 'AUDIO TOOLS' ],
      align: 'left'
    },
    subheading: {
      lines: [ 'DIFFERENT AUDIO PRODUCTION &',
        'MUSIC SOFTWARE' ],
      align: 'left',
      vline: 1,
      fontSize: 0.3
    },
    colors: {
      background: '#aaa',
      accent: '#888',
      c1: '#999',
      c2: '#000',
      c3: '#fff'
    }
  },
  tech: [{
    title: 'Front-end',
    children: [{
      title: 'C/C++',
      children: [{
        title: 'Native GUI'
      }]
    }, {
      title: 'ActionScript'
    }]
  }, {
    title: 'Back-end',
    children: [{
      title: 'C/C++',
      children: [{
        title: 'VST'
      }]
    }]
  }],
  summary: {
    text: null,
    image: '/content/audio/metro.png'
  },
  sections: [{
    title: 'Introduction',
    text: `I've made audio/music related tools ranging from online
      metronomes, interactive scale/chord progression selection tools, audio plugins
      to minimalistic DAWs. The performance critical tools were generally written
      in C/C++ and others using Adobe ActionScript.`,
    images: ['/content/audio/metro.png'],
    imageMode: 'half'
  }, {
    title: 'Music Tools',
    text: `The list of music tools include a web based metronome with a simple
      interface generating a click sound in the most accurate form available to
      a browser based application around the time it was built. The chord
      progression and scale finders were written in Action Script and generated
      MIDI files real-time for quick previews and file exports. These could
      generate common chord progressions, scale patterns and could list all the
      common chords available to the selected scale while displaying the position
      of them in a guitar fretboard with different alternative positions.`,
    images: ['/content/audio/synth1.jpg'],
    imageMode: 'half'
  }, {
    title: 'Synthesizers/Effects',
    text: `I've been experimenting with the development of subtractive synthesizer
      plug-ins, samplers and effects plugins in native VST and JUCE formats, the
      samplers were later ported into an embeddable Linux based drum module which
      could detect and playback samples based on velocity and positional data
      gathered from two piezo sensors mounted into a traditional drum with a mesh
      head.`,
    images: ['/content/audio/drum.jpg'],
    imageMode: 'expanded'
  }]
}

export default data