import React from 'react'
import LazyLoad from 'react-lazyload'

import './Headline.scss'

const config = {
  waveSpeed: 1,
  waveToBlend: 4,
  curverNum: 40,
  framesToMove: 120
}

class WaveNoise {
  constructor(speed) {
    this.waveSpeed = config.waveSpeed
    this.waveSet = [];
    if (speed !== undefined) {
      this.waveSpeed = speed
    }
  }

  addWaves(requiredWaves) {
    for(let i = 0; i < requiredWaves; ++i) {
      let randomAngle = Math.random() * 360;
      this.waveSet.push(randomAngle);
    }
  }

  getWave() {
    let blendedWave = 0;
    for (let e of this.waveSet) {
      blendedWave += Math.sin(e / 180 * Math.PI);
    }

    return (blendedWave / this.waveSet.length + 1) / 2
  }

  update() {
    this.waveSet.forEach((e, i) => {
      let r = Math.random() * (i + 1) * this.waveSpeed;
      this.waveSet[i] = (e + r) % 360;
    })
  }
}

/**
 * Props.content formatting:
 * content = {
 *    heading: {
 *      lines: [ 'Hello', 'World' ],
 *      align: 'left'
 *    },
 *    subheading: {
 *      lines: [ 'The', 'Text' ],
 *      align: 'right',
 *      vline: 1,
 *      fontSize: 0.3
 *    },
 *    colors: {
 *      background: '#000',
 *      accent: '#f00',
 *      c1: 'green',
 *      c2: 'red',
 *      c3: 'magenta'
 *    }
 * }
 */
class Headline extends React.Component {
  constructor (props) {
    super(props)
    this.fps = 15
    this.nWaves = 40
    this.ripples = []
    this.state = { }
    this.canvas = React.createRef();
    this.waves = []
    this.updateAnimationState = this.updateAnimationState.bind(this);
  }

  componentDidMount() {
    this.rAF = requestAnimationFrame(this.updateAnimationState);
    this.gradientWave = new WaveNoise(0.3)
    this.gradientWave.addWaves(config.waveToBlend);

    this.waves = []
    for (let i = 0; i < 4; i++) {
      let wave = new WaveNoise(0.4)
      wave.addWaves(config.waveToBlend)
      this.waves.push(wave)
    }

    this.ripples = []
    for (let i = 0; i < this.nWaves; i++) {
      let wave = new WaveNoise(0.8)
      wave.addWaves(config.waveToBlend)
      this.ripples.push(wave)
    }
  }

  drawWave(ctx, w, h, x1, y1, x2, y2, vert) {
    ctx.save()
    ctx.beginPath();
    ctx.moveTo(0, this.waves[2].getWave() * h * (vert / 1));
    ctx.bezierCurveTo(x1, y1, x2, y2, w, vert * h / 2)
    ctx.stroke();
    ctx.restore()
  }

  updateAnimationState() {
    const canvas = this.canvas.current

    if (!canvas) {
      setTimeout(() => {
        this.rAF = requestAnimationFrame(this.updateAnimationState)
      }, 1000 / this.fps);
      return
    }

    const ctx = canvas.getContext('2d')
    const content = this.props.content

    let width = 2000
    let height = 1200
    let expWidth = 0
    let headingY = 0
    let subheadingY = 0
    let headingVLines = []

    const headingMarign = 40
    const subheadingMargin = 10
    

    ctx.clearRect(0, 0, width, height)
    
    ctx.font = "bold 300px Oswald"
    content.heading.lines.forEach(line => {
      const metrics = ctx.measureText(line)
      const height = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
      headingVLines.push(headingY)
      headingY += height
      ctx.fillText(line, 0, headingY)
      headingY += headingMarign
      if (metrics.width > expWidth) expWidth = metrics.width
    })

    headingVLines.push(headingY)

    let gradientMid = this.gradientWave.getWave()

    ctx.save()
    var grd = ctx.createLinearGradient(0, 0, width, 0);
    grd.addColorStop(0, content.colors.background);
    grd.addColorStop(Math.max(gradientMid - 0.1, 0.11), content.colors.background);
    grd.addColorStop(gradientMid, content.colors.accent);
    grd.addColorStop(Math.min(gradientMid + 0.1, 0.99), content.colors.background);
    grd.addColorStop(1, content.colors.background);
    ctx.fillStyle = grd
    ctx.globalCompositeOperation = 'source-in'
    ctx.fillRect(0, 0, width, 800)
    ctx.restore()
    ctx.globalCompositeOperation = 'source-over'

    ctx.font = "300 100px Oswald"

    if (content.subheading.vline !== undefined) {
      subheadingY = headingVLines[content.subheading.vline]
    }


    content.subheading.lines.forEach(line => {
      let x = 0
      const metrics = ctx.measureText(line)
      const height = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
      subheadingY += height

      if (content.subheading.align === 'expright') {
        // expanded right align
        x = expWidth - metrics.width
      }


      ctx.fillText(line, x, subheadingY)
      subheadingY += subheadingMargin
    })

    //ctx.fillText("A FULL-STACK DEVELOPER", 2000 - 1200, 450)
    //ctx.fillText("FROM", 2000 - 500, 550)
    //ctx.fillText("COLOMBO, SRI LANKA", 2000 - 1000, 650)

    ctx.save()
    var grd2 = ctx.createLinearGradient(0, 0, width, 0);
    grd2.addColorStop(0, content.colors.background);
    grd2.addColorStop(0.1, content.colors.c1);
    grd2.addColorStop(0.5, content.colors.c2);
    grd2.addColorStop(0.9, content.colors.c3);
    grd2.addColorStop(1, content.colors.background);

    ctx.globalCompositeOperation = 'source-atop'
    ctx.strokeStyle = grd2
    ctx.lineWidth = 2
    
    for (let i = 0; i < this.nWaves; i++) {
      this.drawWave(ctx, width, 800,
        this.waves[0].getWave() * width, 
        this.waves[1].getWave() * 800, 
        this.waves[2].getWave() * width, 
        this.ripples[i].getWave() * 800,
        i / this.nWaves)
    }
    ctx.globalCompositeOperation = 'source-over'
    ctx.restore()

    for (let e of this.waves) {
      e.update()
    }

    for (let e of this.ripples) {
      e.update()
    }

    // update
    this.gradientWave.update()

    setTimeout(() => {
      this.rAF = requestAnimationFrame(this.updateAnimationState)
    }, 1000 / this.fps);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.rAF);
  }

  render () {
    return <div className={'headline'} >
      <LazyLoad height={800}>
        <div className={'container'}>
          <canvas ref={this.canvas} width={2000} height={800}/>
        </div>
      </LazyLoad>
    </div>
  }
}

export default Headline
