const data = {
  slug: 'roadhouse',
  title: 'Roadhouse',
  description: `Roadhouse is a location based events platform which shows you
    the events around you. Once you're at the venue, you can discuss different
    things related to the event, share photos and videos and meet people at the
    venue. The app was built to work on all mobile platforms with a focus on
    Windows Phone.`,
  time: '2013',
  category: 'A geo-social network with virtual chat rooms, feed and social connections. Uses python, mongodb and tornado.',
  categoryDescription: 'Location based events app, social network.',
  source: 'https://github.com/s-silva/roadhouse',
  headline: {
    heading: {
      lines: [ 'ROADHOUSE' ],
      align: 'left'
    },
    subheading: {
      lines: [ 'LOCATION BASED EVENTS APP &', 'SOCIAL NETWORK' ],
      align: 'left',
      vline: 1,
      fontSize: 0.3
    },
    colors: {
      background: '#d00',
      accent: '#fa0',
      c1: '#ffcc00',
      c2: '#ff2200',
      c3: '#ffffff'
    }
  },
  tech: [{
    title: 'Front-end',
    children: [{
      title: 'JavaScript',
      children: [{
        title: 'WebSockets'
      }, {
        title: 'JQuery'
      }, {
        title: 'Cordova'
      }, {
        title: 'CSS'
      }]
    }]
  }, {
    title: 'Back-end',
    children: [{
      title: 'Python',
      children: [{
        title: 'Tornado'
      }]
    }, {
      title: 'MongoDB'
    }]
  }, {
    title: 'Plugins'
  }],
  summary: {
    text: null,
    image: '/content/roadhouse/intro.png'
  },
  sections: [{
    title: 'Introduction',
    text: null,
    images: ['/content/roadhouse/intro.png'],
    imageMode: 'expanded'
  }, {
    title: 'How it Works',
    images: [],
    text: `The video below shows how to log into the app, browse events and
    create new posts and events on the app.`,
    video: {
      src: 'https://player.vimeo.com/video/65796298?portrait=1&title=0&amp;byline=0&amp;portrait=0&amp;autoplay=1',
      width: '364',
      height: '610'
    }
  }, {
    title: 'The Feed',
    text: `The feed is a collection of posts made by the people at close by
    events/places and your friends. The feed allows you to share photos taken
    at the venue and make simple text only posts. You can create your own
    content using the camera or the pencil icon below. And those who are seeing
    the post will be able to add their comments to it and cast their votes to
    push the post to the top.

    The feed isn't the only method of communication available on the app, you
    can send private messages to available users using the messaging feature.`,
    images: ['/content/roadhouse/post.png'],
    imageMode: 'expanded'
  }, {
    title: 'Places and Events',
    text: `The main interface of the app displays all the current information
    related to your events schedule. You can add more events to your schedule
    by browsing through local events on the events list, it will provide a map
    and a description of the event. Once an event is selected, you can add the
    event to your personal schedule and see what's going on at the event by
    opening the event feed.`,
    images: ['/content/roadhouse/settings.png'],
    imageMode: 'expanded',
    imageBackground: '#505C68'
  }]
}

export default data