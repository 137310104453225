import fennec from './work/fennec'
import roadhouse from './work/roadhouse'
import natfie from './work/natfie'
import audio from './work/audio'
// import designer from './work/designer'

const data = [
  // designer,
  fennec,
  roadhouse,
  natfie,
  audio
]

export default data