const data = {
  slug: 'fennec',
  title: 'Fennec Media Suite',
  description: `Fennec Media Suite is an open source universal media player/transcoder
  for Microsoft Windows. It supports replaying over 60 audio formats in the highest available
  quality and converting into over 30 different audio formats using highly optimized methods
  allowing comparatively fast conversions. And in addition to that, the dynamic plug-in
  architecture built around the media suite supports adding different DSP effects,
  visualizations, themes and media re-encoding methods.`,
  time: '2003 - 2011',
  category: 'Media player, transcoder.',
  categoryDescription: 'Open source media player, transcoder, media content manager.',
  website: 'https://fennec.sf.net',
  source: 'https://github.com/s-silva/fennec',
  headline: {
    heading: {
      lines: [ 'FENNEC MEDIA', 'SUITE' ],
      align: 'left'
    },
    subheading: {
      lines: [ 'OPEN SOURCE MEDIA PLAYER',
        'TRANSCODER &', 'MEDIA CONTENT MANAGER' ],
      align: 'expright',
      vline: 1,
      fontSize: 0.3
    },
    colors: {
      background: '#fa0',
      accent: '#f50',
      c1: '#ddaa00',
      c2: '#dd0000',
      c3: '#008800'
    }
  },
  tech: [{
    title: 'UI',
    children: [{
      title: 'Skin Modules',
      children: [{
        title: 'Themes'
      }, {
        title: 'Native UI/C++'
      }]
    }]
  }, {
    title: 'Main Program',
    children: [{
      title: 'C'
    }, {
      title: 'C++'
    }]
  }, {
    title: 'Plugins',
    children: [{
      title: 'Audio',
      children: [{
        title: 'Direct X'
      }, {
        title: 'Native Audio'
      }]
    }, {
      title: 'Decoders'
    }, {
      title: 'Encoders'
    }, {
      title: 'Visualizations',
      children: [{
        title: 'Direct X'
      }, {
        title: 'OpenGL'
      }]
    }, {
      title: 'Effects'
    }]
  }],
  summary: {
    text: null,
    image: '/content/fennec/display.png'
  },
  sections: [{
    title: 'Introduction',
    text: null,
    images: ['/content/fennec/intro.jpg'],
    imageMode: 'expanded'
  }, {
    title: 'Media Library',
    text: `Fennec Media Suite 1.3 introduces a media library where you can manage
      your entire music library. It includes metadata and album art scanning from
      both the media files and using an online database. You can search for media
      files real-time and create a playlist or a list for batch conversions. And
      in addition to that, it supports editing metadata of all the selected files
      and adjusting audio levels and performing equalization and noise removal of
      them as a batch.`,
    images: ['/content/fennec/media-library.jpg'],
    imageMode: 'expanded'
  }, {
    title: 'Visualizations',
    text: `Visualizations work as separate plugins added to the system. The suite
      comes with a few OpenGL based visualizations and simple visualizations like
      spectrum analyzers, waveform monitors and spectrograms.`,
    images: ['/content/fennec/visualizations.jpg'],
    imageMode: 'half',
    imageBackground: '#505C68'
  }, {
    title: 'Preferences',
    text: `The main program can be configured to manage the plugins as needed.
      Plugins process audio/video data in every step of the playback/conversion
      process, which includes decoding, encoding, effects/filtering, visualizations
      and audio/video output. Other than that, the main program also manages GUI
      related tasks separately using plugins (skins). The audio quality of the
      engine can be configured here as well, it supports 16 - 32bit fixed point
      processing and 24 - 32bit floating point processing, and the audio plug-ins in
      the media suite are setup to switch their processing based on the bit-depth
      selected here.`,
    images: ['/content/fennec/preferences.png'],
    imageMode: 'half',
    imageBackground: '#505C68'
  }, {
    title: 'Converting Media Files',
    text: `The audio engine of the suite is optimized to process audio files in
      both playback and encoding. Both methods will use a stream of data coming
      from a decoder plugin which will be going through a set of filtering/effects
      plugins. The stream will then be sent to either an audio output or an
      encoder plugin. The amount of data that will be processed at a single take
      will be changed based on the method that's used, making the encoding happen
      faster than many software converters. \n The conversion engine also supports
      batch naming and tagging of media files, sample rate/bit depth and channel
      changes etc.`,
    images: ['/content/fennec/converting.png'],
    imageMode: 'half',
    imageBackground: '#505C68'
  }, {
    title: 'Joining Files',
    text: `The conversion engine also includes a built in interfaces dedicated to
      joining different media files to a single file. Which can be used to create
      mixtapes and large audiobooks and such. This also includes adjustments in
      volume/equalizer levels of all the files that are being converted. The
      generated audio stream can then be converted into about 30 different formats
      based on the user's selection.`,
    images: ['/content/fennec/joining.png'],
    imageMode: 'half',
    imageBackground: '#505C68'
  }, {
    title: 'Metadata/Tags',
    text: `Every decoder plugin in the media suite are setup to handle metadata
      reading and editing of the supported audio file formats. The tag editor
      interface of the main program processes these data and gives the ability
      to edit metadata in batches. The metadata can also be used to rename files
      based on a single format.`,
    images: ['/content/fennec/tag-editor.png'],
    imageMode: 'half',
    imageBackground: '#505C68'
  }, {
    title: 'DSP/Multi-channel Equalizer',
    text: `The audio data can be passed through a series of DSP plugins and then
      through a 16 channel graphic equalizer and a noise reduction filter. This
      was setup to enable both playback and editing of media files made for 5.1/7.1+
      surround sound systems.`,
    images: ['/content/fennec/eq.png'],
    imageMode: 'half',
    imageBackground: '#505C68'
  }]
}

export default data