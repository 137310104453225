import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Layout from './components/Layout'
import HomePage from './pages/Home'
import WorkPage from './pages/Work'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Layout>
          <Route component={ScrollToTop} />
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route path='/work/:project' component={WorkPage} />
            <Redirect to='/' />
          </Switch>
        </Layout>
      </BrowserRouter>
    );
  }
}

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

export default App;
