import React from 'react'
import './Separator.scss'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    function pad(num, size) {
      num = num.toString();
      while (num.length < size) num = `0${num}`;
      return num;
    }

    return <div className='story-separator'>
      <div className='separator-block'>
        <div className='separator-number'>{pad(this.props.index + 1, 2)}</div>
        <div className='separator-caption'>PROJECTS</div>
        <div className='separator-title'>{this.props.content.title}</div>
        <div className='separator-time'>{this.props.content.time}</div>
        <div className='separator-chevron'></div>
      </div>
    </div>
  }
}

export default Header
