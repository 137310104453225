import React from 'react'
import './Layout.scss'

import Header from '../../components/Header'
import Menu from '../../components/Menu'

class Layout extends React.Component {
  constructor (props) {
    super(props)
    this.state = { displayMenu: false }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  toggleMenu () {
    this.setState({ displayMenu: !this.state.displayMenu })
  }

  render () {
    return <div className={'Layout'}>
      <div className='content'>
        {/*<Header  />
        
        <div>S</div>
        <div>Dribbble</div>
        <div>GitHub</div>
  <div>Menu</div>*/}
        <Menu display={this.state.displayMenu} toggleMenu={this.toggleMenu} />
        <Header toggleMenu={this.toggleMenu} />
        {this.props.children}
      </div>
    </div>
  }
}

export default Layout
