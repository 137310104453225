const data = [
  {
    title: 'Serene-UI',
    description: 'A dark minimal UI theme for Atom',
    url: 'https://atom.io/themes/serene-ui'
  }, {
    title: 'rtail',
    description: 'NPM package for remote terminal logs [initial contributor]',
    url: 'https://github.com/kilianc/rtail'
  }, {
    title: 'asc-loader',
    description: 'AssemblyScript loader for Webpack',
    url: 'https://github.com/s-silva/asc-loader'
  }, {
    title: 'imagefx',
    description: 'A vanilla JS image effects library',
    url: 'https://github.com/s-silva/imagefx'
  }
]

export default data